/* @font-face {
  font-family: myFirstFont;
  src: url(./icons/f.ttf);
} */

html {
  background-color: #e3efcb;
}

.hidden {
  color: transparent;
}

body {
  /* font-family: myFirstFont !important; */
  font-family: 'Open Sans', sans-serif;
}

.App {
  color: white;
  font-weight: 500;
  font-size: 1.55em;
}

button {
  align-items: center;
  appearance: button;
  border-style: none;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  text-transform: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  box-shadow: rgba(0, 0, 0, .5) 0 3px 9px 0;
}
.section {
  padding: 30px;
  color: #25408f;
  margin: auto;
}

.header {
  background-color: #25408f;
  border-radius: 0 0 40px 40px;
}
.headerSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 30px;
  max-width: 1200px;
  margin: auto;
  gap: 5%;
}
img.waterDude {
  margin-top: -15px;
}
.highlight {
  color: #207154;
}

.header .highlight {
  color: #97ca3d;
  margin: -5px 0;
}
.headerText {
  text-align: right;
  text-wrap: balance;
}
.didYouKnow {
  font-weight: 500;
}
.instructions {
  font-weight: 700;
}
.instructions, .didYouKnow {
  color: black;
  margin: auto;
  padding: 30px;
  background: white;
  border-radius: 20px;
  box-shadow: 0 0 5px 10px white;
  max-width: 700px;
  font-size: 1rem;
}

.didYouKnow {
  max-width: 300px;
  margin: unset;
  text-align: left;
}

.didYouKnowBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.instructions {
  color: #25408f;
  font-size: 1.4rem;
}
.instructions .highlight {
  font-size: 1.4rem !important;
}

.didYouKnow .highlight {
  font-size: 1.2rem;
  font-weight: 700;
}

.questionSection {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
  margin-bottom: 70px;
}

.buttons {
  grid-area: buttons;
  display: flex;
  gap: 15px;
  justify-content: center;
}

.questionSection button.active {
  background-color: #25408f;
  outline: 2px solid white;
  color: white;
}
.questionSection button {
  font-size: 25px;
  padding: 15px;
  text-align: center;
  border-radius: 10px;
  background-color: #c2c2c2;
  color: black;
  font-weight: 800;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, .5) 0 2px 4px 0;
  box-sizing: border-box;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
}

button:hover, .incrementButton:hover, .decrementButton:hover,
button:focus, .incrementButton:focus, .decrementButton:focus {
  box-shadow: rgba(0, 0, 0, .75) 0 3px 15px 0;
  transform: translateY(-2px);
  outline: 2px solid white;
}
.question {
  font-size: 1.2rem;
  font-weight: 700;
}
.italics {
  font-size: 0.8rem;
  max-width: 600px;
  color: #207154;
}
.question, .caption {
  max-width: 700px;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.questions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.center {
  text-align: center;
}

.input {
  grid-area: input;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: auto;
  font-weight: 800;
}
.decrementButton, .incrementButton {
  border: 1px solid rgb(209,213,219);
  background-color: #207154;
  height: 45px;
  width: 45px;
  font-size: 35px;
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: white;
  text-align: center;
  text-decoration: none #D1D5DB solid;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.inputValue {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 100px;
  font-size: 35px;
  text-align: center;
  border-radius: 10px;
  border: none;
  color: white;
  background-color: #25408f;
}
img.mainLogo {
  padding: 15px;
}
img {
  max-width: min(100%,300px);
}
img.image.icons {
  width: 250px;    
  background: transparent;
  border-radius: 0;
  margin: 15px auto;
}

.resultFlex {
  display: flex;    
  justify-content: space-around;
  gap: 50px;
  margin: auto;
  margin-bottom: 30px;
  font-weight: 700;
  flex-wrap: wrap;
  max-width: 1200px;
}
img.image.icons.robe {
  width: 100px;
}
.resultFlex > div {
  display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 350px;
    max-width: 30%;
    color: #207154;
    gap: 4px;
}

.resultFlex div {
  font-size: 20px;
}
.resultFlex  div.highlight {
  font-size: 30px;
}
.resultFlex div.resultValue {
  font-size: 40px;
  color: #25408f;
}
.controlButtons {
  display: flex;
  justify-content: space-evenly;
  gap: 15px;    
  align-items: center;
}
.controlButtons button {
  font-size: 30px;
  padding: 10px 20px;   
  text-align: center;
  border-radius: 10px;
  font-weight: 800;
  cursor: pointer;
  color: white;
  background-color: #25408f;
}
.italics {
  font-style: italic;
  margin-top: 5px;
  font-weight: 700;
}

.resultFlex .drops {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2px;
  margin: 5px;
  justify-content: center;
}

.steps {
  display: flex;
  gap: 10px;
  align-items: center;
}
.steps div{
  background: #25408f;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;

}
.steps div.active{
  padding: 7px;
  background-color: #207154;
}
.steps div:hover {
  padding: 7px;
}
.dropImg {
  width: 20px;
  height: 20px;
  background-color: transparent;
  padding: 5px;
}
.dropImgWUS {
  width: 20px;
  height: 20px;
  padding: 5px;
  border-radius: 50px;
  background: #dabf70;
  filter: invert(1);
  border-radius: 50px;
}

.resFlex {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  align-items: center;
}

.buttonSteps {
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
}

.resultsLegend {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 50px;
}
.resultsLegend div {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 700;
}
.pageControl {
  bottom: 0%;
  position: fixed;
  width: calc(100% - 100px);
  margin: 30px;
  padding: 20px;
  background: rgb(227 239 203 / 95%);
  border-radius: 8px;
  color: #25408f;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}
.pageControlParent {
  height: 120px;
  padding-top: 50px;
}
.static {
  position: unset !important;
  box-shadow: unset !important;
}
.excessDiv {
  display: flex;
  flex-direction: column;
  color: #25408f;
}
.excessDiv div {
  display: flex;
  align-items: center;
}
.excessDiv img {
  margin-right: 5px;
}

.titleSection {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.results h2 {
  display: flex;
  justify-content: center;
  color: #25408f;
}

h1.highlight, h2.highlight {
  font-weight: 800;
}
h1 {
  font-size: 1.5em;
}
.little {
  display: none;
}
p {
  margin: 0;
}


.outlineBox2 {
  background-color: #f3f8e8;
  padding: 40px 80px;
  margin: auto;
  border-radius: 40px;
  box-shadow: 0 0 5px 4px #d9e9b7;
  width: fit-content;
}

.outlineBox2 h2 {
  font-size: 1.5rem !important;
}

.outlineBox2 .didYouKnowBox .instructions {
  max-width: unset;
  background: unset;
  box-shadow: none;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.outlineBox2 .didYouKnowBox .instructions .highlight {
  font-size: 1.75rem !important;
  font-weight: 700;
}

.outlineBox2 .didYouKnowBox .instructions a.highlight {
  font-size: 2rem !important;
}

.waterResFlex {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.waterResFlex h3 p {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 200px;
}

.waterResFlex a.highlight:first-child {
  font-size: 2rem;
}
.waterResFlex a.highlight:nth-child(2)  {
  font-size: 1.25rem;
}

.funnel {
  filter: invert(16%) sepia(46%) saturate(3586%) hue-rotate(217deg) brightness(98%) contrast(87%);
  width: 50px !important;
  margin: auto !important;
}
.funnel.bolt {
  scale: 0.8;
}

.results .didYouKnow h2.highlight, .results .didYouKnow a.highlight {
  font-size: 1.5rem !important;
  font-weight: 600;
}

.results .didYouKnow  {
  max-width: 450px;
}

.resultValueFunnel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.resultHolder.outlineBox3 {
  background-color: #f3f8e8;
  padding: 20px 40px;
  margin: auto;
  border-radius: 40px;
  box-shadow: 0 0 5px 4px #d9e9b7;
  width: fit-content;
}

.results .questionSection {
  box-shadow: none;
  padding: inherit;
  margin-bottom: 25px;
  margin-top: 25px;
}
.results .questionSection button {
  font-size: 1.5rem;
}
.results .questionSection img {
  margin-left: 11px;
  width: 20px;
}

.resultFlex {
  align-items: center;
}

@media only screen and (max-width: 8200px) {
  .sectionHolder {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
  .questionSection, .questionButtonSection {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .mainLogoControl img.mainLogo {
    height: auto;
    max-width: 80%;
  }
  .App {
    text-align: center;
  }
  input, .buttons {
    margin-bottom: 15px;
  }
  .buttons {
    flex-wrap: wrap;
    justify-content: center;
  }
  .resultFlex > div {
    max-width: 75%;
  }
  .resultBar {
    margin: 5px auto;
    width: 95%;
    margin-top: 15px;
  }
  .input {
     margin-bottom: 10px;
  }
  .questionSection {
    flex-direction: column-reverse;
    margin-right: unset;
    margin-top: unset;
    margin-bottom: 50px;
  }
  .instructions, .didYouKnow {
    padding: 10px;
  }
  img.mainLogo {
    max-width: 200px;
    padding: 0;
  }
  img.image.icons {
    max-width: 65%;
  }
  img.image.icons.home {
    /* max-width: 65%; */
    margin-bottom: 0;
  }
  .App h1 {
    font-size: 1.75rem;
  }
  .App h2 {
    font-size: 1.25rem;
  }
  .App h4 {
    font-size: 1rem;
  }
  .App {
    font-size: 0.9rem;
  }
  .resultFlex div {
    font-size: 1rem;
  }
  .resultFlex div.highlight {
    font-size: 1.3rem;
  }
  .resultFlex div.resultValue {
    font-size: 1.75rem;
  }
  .App button, .controlButtons button {
    font-size: 1rem;
    padding: 10px;
  }
  .App .italics, .App .caption {
    font-size: 0.8rem;
  }
  .decrementButton, .incrementButton{
    font-size: 1.75rem;
    line-height: 2.5rem;
  }
  .inputValue {
    font-size: 1.25rem;
    width: 80px;
  }
  .section {
    padding: 0 30px 0 30px;
    margin: auto;
  }
  .titleSection > img, .resFlex > img {
    max-width: min(50%, 150px);
  }
  .titleSection, .resFlex {
    text-align: left;
    border-radius: 0 0 15px 15px;
    margin: 0 -30px 20px -30px;
    padding: 10px 30px;
    box-shadow: #807b7b80 0 3px 9px 0;
    background-color: #25408f;
  }
  .titleSection .highlight, .resFlex .highlight {
    color: white;
  }
  .big {
    display: none;
  }
  .little {
    display: block;
  }
  .titleSection h1, .resFlex h1 {
    padding-right: 15px;
  }  
  .resultFlex img.image.icons {
    max-width: min(45%, 100px);
    margin: 0;
}
.didYouKnow {
  margin-bottom: -40px;
}
.dropImg, .dropImgWUS {
  width: 15px;
  height: 15px;
}
.resultHolder  {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: auto;
  gap: 20px;
  justify-content: center;
  margin-bottom: 10px;
}
.resultFlex > div {
  max-width: 95%;
}
.instructions {
  margin-top: 30px;
  margin-bottom: 30px;
}
.header {
  border-radius: 0 0 20px 20px;
}
h1.highlight.center {
  margin-top: 0;
}
}


@media only screen and (max-width: 400px) {
.buttonSteps {
  display: none;
}
}

@media only screen and (max-width: 600px) {

    .headerSection {
      flex-direction: column;
    }
    .headerText {
      text-align: center;
    }
  }

  @media only screen and (max-width: 700px) {
  .outlineBox2 {
    padding: 5px;
    margin: 0 -20px 20px;
  }
  .outlineBox2 div.didYouKnowBox {
    margin-bottom: 20px;
  }

  .resultHolder.outlineBox3 {
    padding: 10px 20px;
    border-radius: 8px;
  }
  .funnel {
    display: none;
  }
  .resultValueFunnel {
    justify-content: center;
  }
}

  @media only screen and (max-width: 800px) {

    .waterDude {
      display: none;
    }
    .didYouKnow {
      text-align: center;
    }
    .pageControlParent {
      padding: unset;
    }
    .didYouKnowBox {
      margin-bottom: 50px;
    }
    .instructions {
      margin-bottom: -25px;
    }
    .results .didYouKnowBox {
      margin-bottom: 75px;
    }
}

@media only screen and (min-width: 800px) {
.questionSection, .outlineBox {
  border-radius: 8px;
  color: #25408f;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: auto;
  padding: 40px;
}
.resultsLegend {
  margin-bottom: 0;
}
}



.resultBar {
  text-align: left;
  gap: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}
.resultBar div {
  text-wrap: nowrap;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 5px;
}
.resultBar1 {
  background-color: red;
}
.resultBar2 {
  background-color: green;
}


@media only screen and (max-width: 1200px) {
  .resultFlex {
    flex-wrap: wrap;
  }
}
.results .didYouKnow a.highlight {
  font-weight: 700;
}